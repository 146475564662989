import { Component, OnInit } from '@angular/core';
import { ReplyService } from '../Services/reply.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-reply',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss']
})
export class ReplyComponent implements OnInit {

	repliesList:any;
	loading:any = false;
  	id:any;
  	replyform:FormGroup;
  	submitted = false;

  constructor(private replysrv:ReplyService,
  			  private toastsrv:ToastrService,
  			  private activatedRoute:ActivatedRoute,
  			  private formBuilder:FormBuilder,
          private ngxLoader: NgxUiLoaderService,
          public router:Router
          ) {

  	this.id = parseInt(this.activatedRoute.snapshot.params['id']);
  	this.repliesList = [];
  }

  ngOnInit() {
  	this.getReplies();
  	this.replyform = this.formBuilder.group({
        body: ['', Validators.required],
        data_id:this.id
    });
  }


  
  
  getReplies(){
    this.ngxLoader.start();
    this.replysrv.getReplieslist(this.id).subscribe(
        data => {
           this.dataReadReply(data);
           this.ngxLoader.stop();
        },
        error => {
            this.toastsrv.error(error);
            this.loading = false;
            this.ngxLoader.stop();
        });
  }


  dataReadReply(responseObj) {
    this.repliesList = [];
    if(responseObj.data != undefined) {

      Object.keys(responseObj.data).forEach((index:any) => {
        var replyObj = responseObj.data[index];

        var objdata = {
          data_id:replyObj.data_id,
          subject:replyObj.body,
          created_at:replyObj.created_at,
          Status:'Replied'
        };
        this.repliesList.push(objdata);
      });
      console.log(this.repliesList);
    }
  }


  onSubmit()
  {
  	this.submitted = true;
        // stop here if form is invalid
        if (this.replyform.invalid) {
            return;
            this.toastsrv.success("please try again!");
        }
        console.log(this.replyform.value);
        this.loading = true;
        this.ngxLoader.start();
        this.replysrv.addreply(this.replyform.value).subscribe(
                data => {
                  this.toastsrv.success("success!");
                  this.ngxLoader.stop();
                  this.getReplies();
                },
                error => {
                    this.toastsrv.error(error);
                    this.loading = false;
                    this.ngxLoader.stop();
                });
  	}
}
