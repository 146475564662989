import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReplyService {

  constructor(public http: HttpClient) { }

  getHeaders(token) {
  		const header = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };      
        let headers = new HttpHeaders(header);
        if (token != undefined) {
            let newheader = headers.append('Authorisation',token);
            return newheader;
        }
        else{
            return headers;
        }
    }


    getReplieslist(data_id){
     var token = environment.token;
        var apiURL = environment.apiURL + 'messages/getComplaintReplies?data_id='+data_id+'';
        let newheader = this.getHeaders(token);
        return this.http.get(apiURL, {headers:newheader});
   }

      /**
    * Method: addreply
    * Action: To save notes as case action
    * @param token {string} - authorized token
    * @param data {object} - case note
    * @param case_id {int} - Case ID
    * @returns API response
    */
   addreply(formdata)
   {
      var token = environment.token;
      var apiURL = environment.apiURL + 'messages/createComplaintReplies';
      let newheader = this.getHeaders(token);
      return this.http.post(apiURL,formdata,{headers:newheader});
   }
}
