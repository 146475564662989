import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { DisputesService } from '../Services/disputes.service';
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-createdispute',
  templateUrl: './createdispute.component.html',
  styleUrls: ['./createdispute.component.scss']
})
export class CreatedisputeComponent implements OnInit {

	createdisputesform:FormGroup;
	loading = false;
    submitted = false;
    id:any;

  constructor(private formBuilder:FormBuilder,
  			  private Disputesrv:DisputesService,
  			  private toastsrv:ToastrService,
  			  private activatedRoute:ActivatedRoute,
          private ngxLoader: NgxUiLoaderService,
          public router:Router,
          ) {

          this.ngxLoader.start();
          this.id = this.activatedRoute.snapshot.params['id'];
    }

  ngOnInit() {

  	 this.createdisputesform = this.formBuilder.group({
            complaint_details: ['', Validators.required],
            data_type:'cd_legacy\\models\\Cases',
            complaint_date:new Date(),
            data_id:this.id
        });

  	 /*this.activatedRoute.queryParams.subscribe(params => {
        this.id = params['id'];
      });*/
     this.ngxLoader.stop();
  }


  // convenience getter for easy access to form fields
    get f() { return this.createdisputesform.controls; }


  onSubmit()
  {
  	this.submitted = true;
        // stop here if form is invalid
        if (this.createdisputesform.invalid) {
            return;
            this.toastsrv.success("please try again!");
        }

        this.loading = true;
        this.ngxLoader.start();
        this.Disputesrv.adddispute(this.createdisputesform.value).subscribe(
                data => {
                  this.toastsrv.success("success!");
                  this.ngxLoader.stop();
                  var url = '/disputes/' + this.id;
                  this.router.navigateByUrl(url);
                },
                error => {
                    this.toastsrv.error(error);
                    this.ngxLoader.stop();
                    this.loading = false;
                });
  	}

}
