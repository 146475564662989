import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class DebtsService {

	
  constructor(public http: HttpClient, private toastr: ToastrService) { }

  

  getDebtorcase(Id,debtor_name){
  		var token = environment.token;
        var apiURL = environment.apiURL + 'cases/getDebtorCase?ref='+Id+'&debtor_name='+debtor_name+'';
        let newheader = this.getHeaders(token);
        return this.http.get(apiURL, {headers:newheader});
    }
      payment(data, token){

         //var data = debtor_id,amount,card_no,expiry_month,expiry_year,cvc_no
         //var host = localStorage.getItem("url");
         var apiURL = environment.sagePayURL  + "b/payment/sage_pay_actions/makePayment?source=API";
         let newheader = this.getHeaders(token);
         //return this.http.post(apiURL,data, {headers:newheader});
         this.http
        .post(apiURL,
          data, {
            headers: newheader
          })
          .subscribe(data => {
              this.toastr.success('Payment made successfully');

          }, error => {
              console.log(JSON.stringify(error.json()));
          });
       
    }


  getHeaders(token) {
        const header = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };      
        let headers = new HttpHeaders(header);
        if (token != undefined) {
            let newheader = headers.append('Authorisation',token);
            return newheader;
        }
        else{
            return headers;
        }
    }
}
