import { Component, OnInit } from '@angular/core';
import { ReferanceService } from '../Services/referance.service';
import { FormGroup, FormBuilder, Validators,ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
    selector: 'app-reference',
    templateUrl: './reference.component.html',
    styleUrls: ['./reference.component.scss']
})

export class ReferenceComponent implements OnInit {

    referanceForm: FormGroup;
    submitted = false;
    loading = false;
    debtslist:any;
    display:any;


    constructor(
        public referencesrv:ReferanceService,
        public formBuilder:FormBuilder,
        public router:Router,
        public toastsrv:ToastrService,
        private ngxLoader: NgxUiLoaderService) { 

        this.debtslist = {};

    }

    ngOnInit() {
        this.referanceForm = this.formBuilder.group({
            ReferanceNumber: ['', [Validators.required]],
            ClientReference: ['',[Validators.required]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.referanceForm.controls; }



    getDebtorcase(){
        this.submitted = true;

         if (this.referanceForm.invalid) {
            return;
        }
      
      this.loading = true;
      this.ngxLoader.start();
      
      this.referencesrv.getDebtorcase(this.referanceForm.value.ReferanceNumber, this.referanceForm.value.ClientReference).subscribe(
          data => {
            this.ngxLoader.stop();
            if(data != undefined) {
              this.debtslist = data;
              if (this.debtslist.data != undefined && this.debtslist.data.cases.length > 0) {
                  localStorage.setItem("debtslist",JSON.stringify(this.debtslist));
              if(this.debtslist.data.cases[0].d_outstanding == "0.00") {  
                  this.toastsrv.success("You can't proceed for this as payable amount is 0.");
              }else{
                this.router.navigate(['/makepayment']);
              }
              }else{
                  this.toastsrv.success('We are unable to find your details. Please re-enter them.');
                }
            }
            
          },
          error => {
              //this.alertService.error(error);
              this.toastsrv.error(error);
              this.loading = false;
              this.ngxLoader.stop();
          });
      }

      



}
