import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../Services/dashboard.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

	debtslist:any;

  constructor(
    public dashbser:DashboardService,
     private ngxLoader: NgxUiLoaderService
   ) {
      
      this.ngxLoader.start();
  	  this.debtslist = JSON.parse(localStorage.getItem("debtslist"));
      this.ngxLoader.stop();
   }

  ngOnInit() {
  }

  clearlocalstorage(){
    localStorage.clear();
  }


  
}
