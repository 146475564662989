import { Component, OnInit } from '@angular/core';
import { DisputesService } from '../Services/disputes.service';
import { ToastrService } from 'ngx-toastr';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({
  selector: 'app-disputes',
  templateUrl: './disputes.component.html',
  styleUrls: ['./disputes.component.scss']
})
export class DisputesComponent implements OnInit {

	disputeList:any;
  loading:any = false;
  id:any;

  constructor(private disputesrv:DisputesService,
  			  private toastsrv:ToastrService,
          private activatedRoute:ActivatedRoute,
          private ngxLoader: NgxUiLoaderService) { 

    this.ngxLoader.start();
    this.id = this.activatedRoute.snapshot.params['id'];
  	this.disputeList = [];
  }

  ngOnInit() {
  	this.getDisputes();
  }


  getDisputes(){
    this.ngxLoader.start();
  	this.disputesrv.getDisputeslist(this.id,'case').subscribe(
        data => {
          this.dataRead(data);
          this.ngxLoader.stop();
        },
        error => {
            this.toastsrv.error(error);
            this.loading = false;
            this.ngxLoader.stop();
        });
  }

  dataRead(responseObj) {
  	
  	if(responseObj.data != undefined) {

  		Object.keys(responseObj.data).forEach((index:any) => {
  			var DisputeObj = responseObj.data[index];
        console.log("tes");
        console.log(DisputeObj);
  			var objdata = {
          id:DisputeObj.id,
          data_id:DisputeObj.data_id,
  				refid:DisputeObj.case.ref,
  				complaints_details:DisputeObj.complaint_details,
  				CreatedDate:DisputeObj.complaint_date,
  				Status:DisputeObj.status.name
  			};
  			this.disputeList.push(objdata);
  		});
  	}
  }


}
