import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient,HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ReferanceService {

  constructor(public http: HttpClient) { }

  
   getDebtorcase(Id, client_ref){
      var token = environment.token;
        var apiURL = environment.apiURL + 'cases/getDebtorCase?ref='+Id+'&client_ref='+client_ref+'';
        let newheader = this.getHeaders(token);
        return this.http.get(apiURL, {headers:newheader});
    }



    getHeaders(token) {
        const header = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        };      
        let headers = new HttpHeaders(header);
        if (token != undefined) {
            let newheader = headers.append('Authorisation',token);
            return newheader;
        }
        else{
            return headers;
        }
    }

}
