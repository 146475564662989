import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { DebtsService } from '../Services/debts.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-makepayment',
  templateUrl: './makepayment.component.html',
  styleUrls: ['./makepayment.component.scss']
})
export class MakepaymentComponent implements OnInit {
	@Input() defaultAmount: string;
  	@Input() defaultCard: string;
  	@Input() defaultExpiryMonth: string;
  	@Input() defaultExpiryYear: string;
  	@Input() defaultCVC: string;
   	@Input() defaultdebtorid: string;
  	@Output() submitForm = new EventEmitter();
  	@Output() cancelForm = new EventEmitter<void>();
  form: FormGroup;
  loading:any = false;
  debtslist:any;
  caseList:any;
  debtorid:any;
  refid:any;
  amount:any;


  constructor(private debtsrv:DebtsService,
  			      public router:Router,
              private toastsrv:ToastrService,
              private ngxLoader: NgxUiLoaderService,
              private fb: FormBuilder
              ) { 

    this.ngxLoader.start();
    this.caseList = [];
    this.debtslist = JSON.parse(localStorage.getItem("debtslist"));
    console.log(this.debtslist);

    if (this.debtslist.data != undefined && this.debtslist.data.cases.length > 0) {
       this.caseList = this.debtslist.data.cases;
       this.debtorid = this.debtslist.data.cases[0].debtorid;
       this.amount = this.debtslist.data.cases[0].d_outstanding;
       this.refid = this.debtslist.data.cases[0].ref;
       
    }
   
  }

  display: boolean = false;
  showDialog() {
     this.display = true;  
      //this.debtorid=id;
    
  }

  onDialogClose(event) {
   this.display = event;
  }

  show:boolean = false;
  open(){
    this.show = true;
  }
  close(event)
  {
    this.show = event;
  }
  ngOnInit() {
  this.ngxLoader.stop();
  this.form = this.fb.group({
    
      debtorid : [this.debtorid],
      amount: [this.defaultAmount, Validators.required],
      card: [this.defaultCard, [Validators.required,Validators.pattern(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)]],
      cvc: [this.defaultCVC, Validators.required],
      month:[this.defaultExpiryMonth, Validators.required],
      year:[this.defaultExpiryYear, Validators.required]

    });
  }

    creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { 'invalidCreditCard': true };
    }
  }
   

  submit() {
    this.submitForm.emit(this.form.value);
     var token = "hgggghghghg";
     var x = this.debtsrv.payment(this.form.value,token)
  }

  cancel() {
    this.cancelForm.emit();
  }

}


