import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Services
import { DashboardService } from '../app/Services/dashboard.service';
import { ReferanceService } from '../app/Services/referance.service';
import { DebtsService } from '../app/Services/debts.service';
import { DisputesService } from '../app/Services/disputes.service';
import { ReplyService } from '../app/Services/reply.service';


//Component
import { ReferenceComponent } from './reference/reference.component';
import { HeaderComponent } from './header/header.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './login/login.component';
import { DisputesComponent } from './disputes/disputes.component';
import { DebtsComponent } from './debts/debts.component';
import { CreatedisputeComponent } from './createdispute/createdispute.component';
import { ReplyComponent } from './reply/reply.component';
import { MycontactComponent } from './mycontact/mycontact.component';
import { MakepaymentComponent } from './makepayment/makepayment.component';
import { OfferpaymentComponent } from './offerpayment/offerpayment.component';
import { PasttransactionComponent } from './pasttransaction/pasttransaction.component';
import {ButtonModule,DialogModule} from 'primeng/primeng';
import { CalendarModule } from 'primeng/components/calendar/calendar';





@NgModule({
  declarations: [
    AppComponent,
    ReferenceComponent,
    HeaderComponent,
    DashboardComponent,
    NavBarComponent,
    LoginComponent,
    DisputesComponent,
    DebtsComponent,
    CreatedisputeComponent,
    ReplyComponent,
    MycontactComponent,
    MakepaymentComponent,
    OfferpaymentComponent,
    PasttransactionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgxUiLoaderModule,
    BrowserAnimationsModule,
    ButtonModule, 
    DialogModule,
    CalendarModule
  ],
  providers: [
    DashboardService,
    ReferanceService,
    DebtsService,
    DisputesService,
    ReplyService],
  bootstrap: [AppComponent]
})
export class AppModule { }
