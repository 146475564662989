// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { ReferenceComponent } from '../app/reference/reference.component'
import { DashboardComponent } from '../app/dashboard/dashboard.component'

export const environment = {
  production: false,
  baseurl:'',
  //apiURL:'http://stag356.elm2018.co.uk/b/system/v1/',
  //token:'un4tcem7-sa6c-eclu-778j-4cpnqn2mkppe',
  apiURL:'http://stag356.elm2018.co.uk/b/system/v1/',
  token:'gc0w5mwn-j7cj-kit8-w7oh-088yznhoehmn',
  

  sagePayURL:'http://ces.dcd.local:8081/',

  //apiURL:'http://ces.dcd.local:8081/',
  //token:'0wujkqs0-32m8-lxua-m8o4-vuaeynz7g63g',
  //token:'cliew9u8-15ma-npd0-2hsg-lb39fqvj66si',
  routes: []
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
