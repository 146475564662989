import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//components
import { ReferenceComponent } from '../app/reference/reference.component'
import { DashboardComponent } from '../app/dashboard/dashboard.component';
import { LoginComponent } from '../app/login/login.component';
import { DisputesComponent } from '../app/disputes/disputes.component'
import { DebtsComponent } from '../app/debts/debts.component'
import { CreatedisputeComponent } from './createdispute/createdispute.component';
import { ReplyComponent } from './reply/reply.component';
import { MycontactComponent } from './mycontact/mycontact.component';
import { MakepaymentComponent } from './makepayment/makepayment.component';
import { OfferpaymentComponent } from './offerpayment/offerpayment.component';
import { PasttransactionComponent } from './pasttransaction/pasttransaction.component';




const routes: Routes = [
  {
    path: '', component:ReferenceComponent
  },
  {
    path:'dashboard', component:DashboardComponent
  },
  {
    path:'login', component:LoginComponent
  },
  {
    path:'disputes/:id', component:DisputesComponent
  },
  {
    path:'debts', component:DebtsComponent
  },
  {
    path:'createdispute/:id', component:CreatedisputeComponent
  },
  {
    path:'reply/:id', component:ReplyComponent
  },
  {
    path:'mycontact', component:MycontactComponent
  },
  {
    path:'makepayment', component:MakepaymentComponent
  },
  {
    path:'offerpayment', component:OfferpaymentComponent
  },
  {
    path:'transaction', component:PasttransactionComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
